<template>
  <app-page ref="page" :toolbar="toolbar" :list="list" @loaded="onLoaded">
    <template #actions="{ row }">
      <el-button
          icon="el-icon-delete-solid"
          :loading="deleteloading.loading"
          type="text"
          v-if="row.status == 3"
          @click="onDelete(row)"
      >
        删除
      </el-button>
    </template>
  </app-page>
</template>

<script>
import mixins from './mixins';

export default {
  name: 'Takeaway',
  mixins
};
</script>
