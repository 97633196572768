import request from '@/utils/request';

/**
 * 查询所有用户预订
 * @param {Object} data
 */
export function getAllList(data) {
  return request({
    url: '/canteen/takeaway/getAllList',
    method: 'post',
    data
  });
}

/**
 * 状态修改
 * @param {Object} data
 */
export function updateStatus(data) {
  return request({
    url: '/canteen/appointment/updateStatus',
    method: 'post',
    data
  });
}

/**
 * 扣费或批量扣费
 * @param {Object} data
 */
export function updateCostPrice(data) {
  return request({
    url: '/canteen/appointment/updateCostPrice',
    method: 'post',
    data
  });
}

export function delTake(data) {
  return request({
    url: '/canteen/takeaway/delete',
    method: 'post',
    data
  });
}
